.tableWidth110 {
    width: 120px;
}

.tableWidth120 {
    width: 120px;
}

.tableWidth140 {
    width: 140px;
}

.tableWidth150 {
    width: 150px;
}