@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@import "bootstrap";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  font-family: Inter, sans-serif;
  margin-top: 58px;
  font-size: 0.95rem !important;
}

p {
    font-size: 0.95rem !important;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  .Footer {
    flex: 0 0 auto;
  }
  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }
  .MainHeader {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

.material-icons,
.material-icons-outlined {
  vertical-align: middle;

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 12px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}

.dropdown {
  .dropdown-toggle::after {
    font-family: "Material Icons";
    font-size: 24px;
    color: inherit;
    vertical-align: middle;
    line-height: 1;
    margin: -2px 0 0 0;
    content: "\e5cf";
    border: none;
  }
  .dropdown-toggle.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.navbar-nav {
  .nav-item.dropdown.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.btn:focus {
  box-shadow: none;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}